

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import api from "../../utility/apis";
import BlogCard from "./blog-card";
import i18n from "i18n-js";
import { slice } from "lodash";
import dataNotFound from '../../../static/assets/images/no-data-found.svg'
let list2 = [];
const BlogPage = () => {
  const LIMIT = 3;
  const [categoryBlog, setCategory] = useState("");
  const [allBlogs, setAllBlogs] = useState([]);
  const [showMore, setShowMore] = useState(true);
  const [showDropdown, setDropdown] = useState([]);
  if (categoryBlog == "" && showMore == true || categoryBlog == "7") {
    list2 = slice(allBlogs.data, 0, 3);
  }
  const [i, setIndex] = useState(LIMIT);
  const loadMore = () => {
    const newIndex = i + (allBlogs.length - 1);
    const newShowMore = newIndex < (allBlogs.length - 1)
    setShowMore(newShowMore);
  };
  if (showMore == false) {
    list2 = allBlogs.data;
  }
  async function handleChange(event) {
    setCategory(event.target.value);
    if (event.target.value == '7') {
      getAllBlogs()
    } else {
      let { data } = await api
        .get(`/trd-portal-blog-articles?populate=*&filters[trd_portal_blog_category][id][$eq]=${event.target.value}`, 3000)
        .catch((err) => console.log("something went wrong!!!"));
      list2 = data.data;
      if (list2.length < 3) {
        setShowMore(false)
      } if (data != undefined) {
        setAllBlogs(data);
      }
    }
  }
  const getAllBlogs = async () => {
    let { data } = await api
      .get("/trd-portal-blog-articles?populate=*&sort=id:asc", 3000)
      .catch((err) => console.log("something went wrong!!!"));
    setAllBlogs(data);
    console.log(data)
    list2 = data.data
    console.log("list2", list2)
  };
  const getAllDropdown = async () => {
    let datadropdown = [];
    let { data: category } = await api
      .get("/trd-portal-blog-categories?sort=id:asc", 3000)
      .catch((err) => console.log("something went wrong!!!"));
    if (category) {
      category.data.map((ele) => {
        datadropdown.push({
          name: ele.attributes.name,
          id: ele.id
        });
      });
      setDropdown(datadropdown);
    }
  };
  useEffect(() => {
    getAllBlogs().catch((err) => console.log("something went wrong!!"));
    getAllDropdown().catch((err) => console.log("something went wrong!!"));
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  return (
    <section
      className="news-page blog-section1 blog-bg-image blog-page-bg"
      id="blog-dropdown"
    >

      <Container className="mb-3">
        <Row noGutters={true} className="justify-content-center">
          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
            <h1 className="text-center blog-heading">{i18n.t("blog-page-heading")}</h1>
          </Col>
        </Row>
      </Container>
      <Container className="blog-section1-dropdown">

        <Row noGutters={true} className="justify-content-center">
          <Col xs={12} sm={12} md={7} lg={5} xl={5} className="text-align-last blog-category-dropdown">

            <select
              name="interest"
              id="interest"
              className="classic dropdown-category"
              onChange={handleChange}
              value={categoryBlog}
            >
              <option value='7'>Display all</option>
              {showDropdown && showDropdown.length > 0 &&
                showDropdown.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </Col>
          {" "}
        </Row>
      </Container>
      <Container>
      <div className="news-3-col">
          {console.log("list2", list2)}
          {list2 && list2.length == 0 ?
            <> 
               <span className="text-center"> <img src={dataNotFound} /><br/>
             
              <p className="text-center no-data">{i18n.t("no-data")}</p></span>
            </>
            
            : 
            list2.map((item, index) => {
              const particularBlog =
                <BlogCard
                  key={index}
                  image={(item.attributes.image.data.attributes.formats != null && item.attributes.image.data.attributes.formats.large) || (item.attributes.image.data.attributes.formats != undefined && item.attributes.image.data.attributes.formats.large) ? item.attributes.image.data.attributes.formats.large.url : item.attributes.image.data.attributes.url}
                  title={item.attributes.title}
                  date={item.attributes.publishedAt}
                  categoryBlog={categoryBlog}
                  category={item.attributes.description}
                  link={'/blog-details' + "?" + item.id}
                  commentCount={item.attributes.commentCount}
                  author={item.attributes.trd_portal_blog_writer.data ? item.attributes.trd_portal_blog_writer.data.attributes.name : ""}
                />
              return particularBlog;
            })}
        </div>
        <div className="text-center mt-5 form-one">
          {showMore && (
            <Button onClick={loadMore} className="thm-btn">
              {" "}
              {i18n.t("load-more")}{" "}
            </Button>
          )}
        </div>
      </Container>
    </section>
  );
};
export default BlogPage;
