
import React,{useEffect} from "react";
import { Link } from "gatsby";
import i18n from "i18n-js";
import 'aos/dist/aos.css';
import AOS from 'aos';
const BlogCard = ({
  image,
  title,
  date,
  text,
  link,
  author,
  category,
  categoryBlog,
}) =>
 {
  let dateArray = new Date(date);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  useEffect(() => {
    AOS.init({
      duration : 2000
    })
})
  console.log(dateArray, "arr")
  return (
    <div className="blog-card" data-aos="zoom-in-down">
      <div className="blog-card__inner" height="100%">
        <div className="blog-card__image">
          <img src={image} alt="" height="270px" />
          <div className="blog-card__date">{dateArray.toLocaleDateString("en-in", options)}</div>
        </div>
        <div className="blog-card__content">
          <div className="blog-card__meta">
            {author && (
              <Link to={link}>
                <i className="far fa-user-circle"></i> {author}
              </Link>
            )}
          </div>
          <h3>
            <Link to={link}>{title}</Link>
          </h3>
          <p>{category.slice(0, 45)}...</p>
          <Link className="blog-card__more" to={link}>
            <i className="far fa-angle-right"></i>{i18n.t("read-more")}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default BlogCard;

